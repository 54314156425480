<template>
    <div id="sub-group">
        <h4 class="tw-mb-2">{{ $t("company.companyInfo") }}</h4>
        <Tabs>
            <TabPane :label="$t('company.mission')">
                <ckeditor v-model="model.company_mission" style="height: 500px;"></ckeditor>
            </TabPane>
            <TabPane :label="$t('company.vision')">
                <ckeditor v-model="model.company_vision"></ckeditor>
            </TabPane>
            <TabPane :label="$t('company.coreValues')">
                <ckeditor v-model="model.core_values"></ckeditor>
            </TabPane>
        </Tabs>
    </div>
</template>

<script>
import Vue from "vue";
import CKEditor from 'ckeditor4-vue';

Vue.use( CKEditor );

export default {
    name: "companyInfo",
    props: ["value"],
    data() {
        return {
            loading: false,
            visible: false,
            model: {}
        };
    },
    created() {
        this.model = this.value;
    }
};
</script>
<style>
.cke_notifications_area{
    display: none !important;
}
</style>
